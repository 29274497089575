import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PodcastPage = () => (
  <Layout>
    <SEO title="Podcast" />
    <section className="intro">
      <div className="intro-container">
        <h2>Podcast</h2>
        <p>Listen to the ALKALine podcast, which aims to bring Asian voices to the kink and fetish community together with underserved and underrepresented voices in the kink and fetish worlds.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <p>New episodes coming soon.</p>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export default PodcastPage
